export type SiteConfig = typeof siteConfig
export const siteConfig = {
    name: "Learn to fly 3",
    slogan: "slogan",
    icon: "/favicon.ico",
    appleIcon: "/apple-touch-icon.png",
    gameIframe: "https://games.crazygames.com/en_US/learn-to-fly/index.html?v=1.305",
    domain: "learntofly3.online",
    gaId: "G-ZPLGQW7B87",
    navbarItems: [
        {
            title: "title",
             href: "/",
        }
    ],
}
